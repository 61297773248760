import React from "react";

export const EngineContext = React.createContext(null);

class Placeholder {
    getAccountInfo() {
        return {};
    }
}

export const TokenContext = React.createContext(new Placeholder());
