import invariant from "invariant";
/**
 * Drivers for the interaction with remote URLs.
 * They open the communication with the URL with the `.open()` method
 * and communicate from the called URL with the opening application
 */

/**
 * Parse url query into a literal object.
 * Keys without value are ignored.
 *
 * @param {string} query - Query string to parse
 * @return {Object<string,string>} Map of queries and their associated values
 */
const parseQuery = (query) => query.split("&")
    .map(pair => pair.split("="))
    .reduce((result, [key, value]) => {
        result[key] = decodeURIComponent(value);
        return result;
    }, {});

const getFromAccounts = () => {
    const query = parseQuery(window.location.hash.slice(1));
    return query.access_token ? query : null;
};

class Timeout extends Error {};

const log  = process.env.NODE_ENV !== 'test'
    ? (...args) => console.log(window === window.top ? 'main' : window.name, 'connect::driver', ...args)
    : () => {};

export class FallbackDriver {
    constructor() {
        this._iframe = new IFrameDriver({
            timeout: 1000,
            onTimeout: () => {
                throw new Timeout();
            }
        });
        this._fallback = new RedirectDriver();
    }

    async open(url) {
        const payload = getFromAccounts();
        try {
            // If the payload is not already present
            if (payload === null) {
                log('activate main');
                return await this._iframe.open(url);
            }
        } catch(err) {
            // Continue to fallback in case of timeout
            if (!(err instanceof Timeout)) {
                log('unexpected', err);
                throw err;
            }
        }
        log('activate fallback');
        return await this._fallback.open(url);
    }

    resolve() {
        // Resolve from the iframe as the resolution from RedirectDriver would
        // have happened at open earlier
        return this._iframe.resolve();
    }
}

class IFrame {
    constructor(url) {
        const frame = this.frame = document.createElement("iframe");
        frame.name = 'oauth-iframe';
        frame.src = url;
        frame.style.border = "none";

        // Start invisible
        frame.style.visibility = "hidden";
        frame.style.position = "absolute";
        frame.style.left = "-9999px";
        frame.style.top = "-9999px";
        frame.style.height = "0px";
        frame.style.width = "0px";

        // Promise that resolves when the iframe has loaded
        const loading = new Promise(resolve => {
            frame.onload = (ev) => {
                resolve();
            };
        });

        // Promise that resolves when the iframe tells the opener through the
        // message API that it has resolved
        this.resolution = loading.then(() => new Promise((resolve, reject) => {
            log('wait for resolution');
            const listener = this.listener = (ev) => {
                if (ev.origin !== window.location.origin) {
                    return;
                }

                const { type, payload } = ev.data;
                if (type !== PopupDriver.MESSAGE_TYPE) {
                    return;
                }

                resolve(payload);
            };

            window.addEventListener("message", listener, false);
        }));

        document.body.appendChild(this.frame);
    }

    get closed() {
        return this.listener === null;
    }

    close() {
        if (this.listener === null) {
            // already closed
            return;
        }

        log('IFrame resolved, remove from body');
        document.body.removeChild(this.frame);
        window.removeEventListener("message", this.listener, false);
        this.listener = null;
    }

    makeVisible() {
        this.frame.style.visibility = "unset";
        this.frame.style.position = "absolute";
        this.frame.style.left = "0";
        this.frame.style.top = "0";
        this.frame.style.height = "100%";
        this.frame.style.width = "100%";
    }
}

export class IFrameDriver {
    // the type of the message posted through the postMessage API for the
    // communication between the popup and the opening application
    static MESSAGE_TYPE = "WeezeventAuthFrame";

    constructor({ onTimeout, timeout }={}) {
        this.onTimeout = onTimeout || (frame => frame.makeVisible());
        this.timeout = timeout || 1000;
    }

    async open(url) {
        const frame =  new IFrame(url);

        // Return a Promise that either
        // * rejects when a timeout happens
        // * resolve when the iframe resolves
        await frame.loading;
        try {
            return await new Promise((resolve, reject) => {
                frame.resolution.then(resolve);

                setTimeout(() => {
                    // If the frame is still in the dom it did not succeed
                    if (!frame.closed) {
                        log('IFrame timeout');
                        this.onTimeout(frame);
                    }
                }, this.timeout);
            });
        } finally {
            frame.close();
        }
    }

    resolve() {
        invariant(window.frameElement, 'resolve is called from inside the iframe');
        const payload = getFromAccounts();
        log('resolve Iframe');
        window.parent.postMessage({
            type: PopupDriver.MESSAGE_TYPE,
            payload,
        }, window.location.origin);
    }
}

/**
 * Popup mode. It pops up a window to handle oAuth request.
 * When done, the callback is loaded into the popup which then
 * communicates with its parent to propagate received data.
 */
export class PopupDriver {
    // the type of the message posted through the postMessage API for the
    // communication between the popup and the opening application
    static MESSAGE_TYPE = "WeezeventAuthPopup";

    // The settings of the popup
    static POPUP_SETTINGS = Object.entries({
        height: 500,
        width: 500,
        resizable: false,
        menubar: false,
        toolbar: false,
        location: false,
        personalbar: false,
        status: false
    })
        .map(([key, value]) => `${key}=${value}`)
        .join(',');

    constructor(settings) {
        this._settings = settings || PopupDriver.POPUP_SETTINGS;
    }

    // resolve the call. It's called in the popup window and posts the payload
    // to the opening window
    resolve() {
        const payload = getFromAccounts();
        log('resolve Popup');

        const opener = window.opener || window.parent;
        opener.postMessage({
            type: PopupDriver.MESSAGE_TYPE,
            payload,
        }, window.location.origin);

        window.close();
    }

    // Open a popup, an listen for the popup to resolve with the payload
    open(url) {
        const popup = window.open(url, 'oAuth', this._settings);
        if (!popup) {
            // Something failed in the creation of the popup
            throw new Error("Popup failed to open");
        }

        return new Promise((resolve, reject) => {
            popup.onbeforeunload = () => {
                log('popup closed');
                reject('closed');
            };

            const listener = (ev) => {
                if (ev.origin !== window.location.origin) {
                    return;
                }

                const { type, payload } = ev.data;
                if (type !== PopupDriver.MESSAGE_TYPE) {
                    return;
                }

                log('popup resolved');
                window.removeEventListener("message", listener, false);
                resolve(payload);
            };

            window.addEventListener("message", listener, false);
        });
    }
}

/**
 * Redirect mode. It redirects the user to handle oAuth request.
 * When done, the callback is launched on the same page and the token stored
 * Since the parent app is not fully loaded until this happens, promises can be kept the same
 */
export class RedirectDriver {
    resolve() {
        // we should not resolve from the redirect driver
        // Either its ok at open, or it's redirected and lost
    }

    open(url) {
        const payload = getFromAccounts();
        if (payload) {
            // We came back from accounts already!

            // clear the hash, that contained the authentication token
            const url = new URL(window.location);
            url.hash = "";
            // Redirect to the initial uri
            url.pathname = payload.state || "/";
            window.history.replaceState({}, "", url);
            log('resolve Redirect');

            // resolve now
            return payload;
        }

        // Go to accounts
        window.location.assign(url);
        // After the location is changed, this page won't resolve.
        // A new page will load at the end of the authentication
        return new Promise(resolve => {});
    }
}

// default driver
export default IFrameDriver;
