import React from 'react';
import { fetcher } from './fetch';
import ModelProxy from './utils/modelProxy';
import { ArgumentError, MustBeOverridenError } from './utils/errors';

export class BaseAPIModel {
    constructor(fromAPI) {
        if (fromAPI instanceof this.constructor) {
            return fromAPI;
        }
        this.__update = {};
        this.object = {...fromAPI} || {};
        this.original_object = {...fromAPI} || {};
        return new Proxy(this, ModelProxy);
    }

    static deprecate(name, message='') {
        console.warn(`BaseAPIModel (${this.name}): using ${name} is deprecated. ${message}`);
    }

    /**
     * Create model list from api return
     */
    static list(apiList) {
        return apiList.map(item => this.fromJSON(item));
    }

    /**
     * Create single item from api return
     */
    static fromJSON(item) {
        return new this(item);
    }

    /**
     * Gets the url for this object(s)
     */
    static listUrl() {
        throw new MustBeOverridenError('Model#listUrl must be overriden by each object');
    }

    /**
     * Creates a query string to append to each url
     */
    static getListUrl(params = {}) {
        return this.listUrl(params);
    }

    /**
     * Gets the url for this object(s)
     */
    static getItemUrl(params) {
        if (!params.id) {
            throw new ArgumentError(`[Model][${this.constructor.name}] id is mandatory`);
        }

        return `${this.getListUrl(params)}/${params.id}`;
    }

    /**
     * Get all items from api
     */
    static getAll(params, query) {
        if(query) {
            this.deprecate('query on getAll()', 'Add it to params.');
        }

        let url = this.getListUrl(params);
        // Must be caught later
        return this.fetcher.get(url, { query, ...params }).then(({ response, headers, status }) => {
            let list = this.list(response);
            list.__meta = { headers, status, response };
            list.__params = params;
            return list;
        });
    }

    /**
     * alias for @getAll
     */
    static filter(params, query) {
        return this.getAll(params, query);
    }

    /**
     * Get a single item from api
     */
    static get(params, query) {
        if(query) {
            this.deprecate('query on get()', 'Add it to params.');
        }

        let url = this.getItemUrl({ ...params });

        // Must be caught later
        return this.fetcher.get(url, { query, ...params }).then(({ response, headers, status }) => {
            let ret = this.fromJSON(response);
            ret.__meta = { headers, status, response };
            ret.__params = params;
            return ret;
        });
    }

    /**
     * Gets the fields (and various settings on them for this object)
     */
    fields() {
        throw new MustBeOverridenError('Model#fields() must be overriden by each object');
    }

    /**
     * Auto resolve the PK from fields description
     */
    _get_pk() {
        let fields = this.fields();
        let primaryKeys = Object.keys(fields).filter(key => fields[key].primaryKey);
        if (primaryKeys.length > 1) {
            throw new Error(`[Model][${this.constructor.name}] has more than one primaryKey`);
        }
        if (primaryKeys.length === 0) {
            throw new Error(`[Model][${this.constructor.name}] has no primaryKey`);
        }
        return primaryKeys[0];
    }

    /**
     * Get PK of model
     */
    get pk() {
        let pk = this._get_pk();
        return this.object[pk];
    }

    /**
     * Set PK of model resolving automatically
     */
    set pk(value) {
        if (this.pk) {
            throw new Error(`[${this.constructor.name}] Cannot change primary key`);
        }

        let pk = this._get_pk();
        this.object[pk] = value;
    }

    /**
     * check if a field is writable for API output
     */
    _is_writable_field(key) {
        // Ignore object property
        // __query is retrocomp
        if (['object', '__update', '__meta', '__params', '__query'].includes(key)) {
            return false;
        }

        let fields = this.fields();

        let field = fields[key];

        // Ignore properties not set
        if (!field) {
            return true;
        }

        if ('readOnly' in field && field.readOnly) {
            return false;
        }

        return true;
    }

    /**
     * check if a field is primary key for API output
     */
    _is_primary_key_field(key) {
        // Ignore object property
        if (key === 'object') {
            return false;
        }
        const fields = this.fields();

        const field = fields[key];

        // Ignore properties not set
        if (!field) {
            return false;
        }

        if ('primaryKey' in field && field.primaryKey) {
            return true;
        }

        return false;
    }

    _has_changed(key) {
        return this.object[key] !== this.original_object[key];
    }

    /**
     * Format object for API
     */
    toAPI() {
        let content = Object.keys(this.object)
            .filter(key => this._is_writable_field(key) || this._is_primary_key_field(key))

        // Handle minimal patches
        // Problematic in usage. Many patches are made by re-creating objects
        // and using the `id` to make sure it triggers as patch

        // if(this.pk) {
        //     content = content.filter(key => this._has_changed(key) || this._is_primary_key_field(key));
        // }

        return content.reduce((res, key) => {
            res[key] = this.object[key];
            return res;
        }, {});
    }

    // Shadow object methods:
    // Their objective is to allow partial modification of an object without
    // committing to the changes, allowing for data flow to be as streamlined
    // as possible and only committing when ready.
    /**
     * Display a copy of object on order to do not propagate changes in real object
     * @returns {{}}
     */
    copy() {
        return new this.constructor({...this.object});
    }

    /**
     * Updates an object without committing
     */
    update(key, value) {
        this.__update[key] = value;
        return this;
    }

    _updateInPlace(fromAPI) {
        for(const [k, v] of Object.entries(fromAPI)) {
            this.object[k] = v;
            this.original_object[k] = v;
        }
    }

    /**
     * Gets a value from the object. Special method to juggle between __update and object
     */
    get(prop) {
        if (!prop) {
            return undefined;
        }

        return this.__update[prop] || this[prop];
    }

    /**
     * Commit updated changes
     */
    commit() {
        if (!Object.keys(this.__update).length) {
            // maybe warn ?
            return;
        }

        for (let k in this.__update) {
            this[k] = this.__update[k];
        }

        this.restore();
        return this;
    }

    /**
     * Restores shadow model to no values
     */
    restore() {
        this.__update = {};
    }

    /**
     * Save item to API
     */
    save(params={}, query={}) {
        if(query) {
            this.constructor.deprecate('query on save()', 'Add it to params.');
        }

        const data = this.toAPI();
        // Set pk to get correct url
        const newParams = {
            ...this.__params,
            ...params,
            id: this.pk
        };

        delete newParams.metadata;

        let promise = null;
        if(this.pk) {
            const url = this.constructor.getItemUrl(newParams);
            promise = this.constructor.fetcher.patch(url, { query, ...params, data });
        } else {
            const url = this.constructor.getListUrl(newParams);
            promise = this.constructor.fetcher.post(url, { query, ...params, data });
        }

        return promise.then(result => {
            const { response } = result;
            this._updateInPlace(response instanceof Object ? { ...response } : response);

            // Clear all the list cache in order
            // to retrieve fresh lists when needed
            // In the future we coud just "update" the list on cache ;)
            // but we have to think about it (filters...)
            this.constructor.fetcher.clear_cache(this.constructor.listUrl(newParams));
            if(this.pk) {
                // We need to set pk again because if we create, we "update in place" and we set a new pk
                this.constructor.fetcher.clear_cache(this.constructor.getItemUrl({...newParams, id: this.pk}));
            }

            // Retrocompat
            return result;
        });
    }

    /**
     * Save objects of this class to API (multiple or one)
     */
    static save(objects, params, query) {
        if (!(objects instanceof Array)) {
            throw new Error(`Can only update multiple objects`);
        }

        if (objects.some(object => !(object instanceof this))) {
            throw new Error(`[Model][${this.name}] Cannot save objects from other models`);
        }

        if(query) {
            this.deprecate('query on save()', 'Add it to params.');
        }

        let data = objects.map(object => object.toAPI());

        // retro compat for query
        let url = this.getListUrl(params);

        // No object has PK so we POST
        let promise = null;
        if (objects.every(object => !object.pk)) {
            promise = this.fetcher.post(url, { query, ...params, data });
        }

        // All objects have pk so we PATCH
        if (objects.every(object => object.pk)) {
            promise = this.fetcher.patch(url, { query, ...params, data });
        }

        if(!promise) {
            throw new Error(`[Model][${this.name}] Please save _only_ new or _only_ modified objects`);
        }

        return promise.then(ret => {
            // TODO
            // update every object in place

            // Clean all "list" caches
            this.fetcher.clear_cache(this.listUrl(params));
            return ret;
        });
    }

    /**
     * Delete in API
     */
    delete(params, query, data) {
        if (!this.pk) {
            throw Error('Cannot delete an object without pk');
        }
        return this.destroy(params, query, data);
    }

    /**
     * Destroy objects of this class (multiple or one)
     */
    static destroy(objects, params, query){
        if (!(objects instanceof Array)) {
            objects = [objects];
        }

        if (objects.some(object => !(object instanceof this))) {
            throw new Error(`[Model][${this.name}] Cannot delete objects from other models`);
        }

        if(query) {
            this.deprecate('query on destroy()', 'Add it to params.');
        }

        let singleObject = objects.length === 1;
        if (singleObject && objects[0].pk) {
            params.id = objects[0].pk;
        }

        let data = singleObject ? objects[0].toAPI() : objects.map(object => object.toAPI());
        let url = singleObject && objects[0].pk ? this.getItemUrl(params) : this.getListUrl(params);

        if (objects.every(object => object.pk)) {
            return this.fetcher.delete(url, { query, ...params, data }).then(ret => {
                this.fetcher.clear_cache(this.listUrl(params));
                return ret;
            });
        }
        throw new Error(`[Model][${this.name}] Please save objects before delete them`);
    }

    /**
     * Delete in API according pk or not
     */
    destroy(params, query, data) {
        // v1
        if(query && !data) {
            // set data to query value and query to undefined
            [data, query] = [query, undefined];
        }

        if (this.pk) {
            params.id = this.pk;
            let url = this.constructor.getItemUrl({ query, ...params });
            const options = {...params};

            // Sometimes we need to send data to DELETE route
            if (data) {
                options.data = data;
            }

            return this.constructor.fetcher.delete(url, options);
        }

        // if no pk
        let url = this.constructor.getListUrl(params);
        return this.constructor.fetcher.delete(url, params);
    }

    static sort(array) {
        if (!array.length || array[0].display_order === undefined) {
            return array;
        }
        return array.sort((el1, el2) => el1.display_order - el2.display_order);
    }

    static useApiModel(params = {}, deps=[]) {
        const [models, setModels] = React.useState(params.initial ?? null);
        const [loading, setLoading] = React.useState(params.defaultLoading ?? true);
        const [error, setError] = React.useState(null);

        const sync = React.useCallback((newParams = {}) => {
            if(params.launch === false) {
                return;
            }

            delete params.launch;

            setLoading(true);
            let _params = {...params, ...newParams};

            // If id we get a single object, otherwise we get all objects
            let func = this.getAll.bind(this);
            if(Object.prototype.hasOwnProperty.call(_params, "id")) {
                func = this.get.bind(this);
            }

            func(_params)
                .then(res => {
                    setModels(res);
                    _params.onSuccess && _params.onSuccess(res);
                })
                .catch(e => {
                    setError(e);
                    _params.onError ? _params.onError(e) : console.error(e);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, [params]);

        // Load first time
        React.useEffect(() => {
            sync();
        }, deps);

        return [models, loading, sync, error];
    }
}

BaseAPIModel.fetcher = fetcher;
