import invariant from "invariant";
import { Token } from "./models";
import DefaultDriver from "./drivers";

// Refresh at least every 30 minutes
const MAX_LIFETIME_DURATION = 30 * 60 * 1000;

// Reload 60 seconds before the expiration of the token
const MAX_DELAY_BEFORE_EXPIRE = 60;

const log  = process.env.NODE_ENV !== 'test'
    ? (...args) => console.log(window === window.top ? 'main' : window.name, 'connect::index', ...args)
    : () => {};


export default class Connect {
    constructor({
        endpoints,
        clientId,
        driver,
        onResolve,
        redirectURI,
        maxDelayBeforeExpire = MAX_DELAY_BEFORE_EXPIRE,
        maxLifetimeDuration = MAX_LIFETIME_DURATION,
    }) {
        invariant(typeof endpoints === 'object', 'endpoints is required');
        invariant(clientId, 'clientId is required');

        this.clientId = clientId;

        // driver to talk to accounts
        this._driver = driver || new DefaultDriver();

        // setTimeout ID of the autoreload if it's in progress
        this._autoreloadID = null;
        // Pending authentication request when it happens in background
        this._pending = null;

        // Callback when the token is resolved
        this.onResolve = onResolve || (() => {});

        invariant(redirectURI, 'redirectURI is required');
        this.redirectURI = redirectURI;

        // Maximum remaining lifetime of a token before refresh
        this.maxDelayBeforeExpire = maxDelayBeforeExpire;
        // Maximum lifetime of use a of token before refresh
        this.maxLifetimeDuration = maxLifetimeDuration;

        this._endpoints = endpoints;
    }

    _getEndpoint(target) {
        invariant(target in this._endpoints, `missing ${target} in endpoints`);
        return this._endpoints[target];
    }

    _generateURL(target) {
        const state = encodeURIComponent(window.location.pathname);
        return (
            `${this._getEndpoint(target)}?client_id=${this.clientId}` +
			`&state=${state}&redirect_uri=${this.redirectURI}&response_type=token`
        );
    }

    /** Automatically refresh the token in a few minutes */
    _autoRefresh(token) {
        // A refresh is already pending, bail
        if (this._autoreloadID) {
            return;
        }

        const remaingLifetime = token.expire - (Date.now() / 1000);
        // Generate the delay, at least a few seconds before it expires
        // at most MIN_DELAY
        const delay = Math.min(
            Math.max(remaingLifetime - this.maxDelayBeforeExpire, this.maxDelayBeforeExpire),
            this.maxLifetimeDuration
        );

        // The duration before expiry is the exp in milliseconds - now
        // We will refresh EXPIRE_MARGIN in milliseconds before the expiration of the token
        // A jitter of up to 10 s is added to avoid conflicts between tabs
        log('reload in', delay, 's');
        this._autoreloadID = setTimeout(() => {
            log('activate autoreload');
            this._autoreloadID = null;
            this._signin();
        }, (Math.random() * 10 + delay) * 1000);
    }

    /** (re)Authentication process */
    async _signin() {
        if (this._pending) {
            log('Wait pending');
            return this._pending;
        }

        log('Start signin');
        const url = this._generateURL('authorization_endpoint');
        this._pending = this._driver.open(url);
        try {
            // Wait from the driver to finish the auth
            const response = await this._pending;
            log('resolved from driver');
            // Create a new token
            const token = new Token(response);
            // Notify the callback
            this.onResolve(token);
            // Trigger auto refresh
            this._autoRefresh(token);
        } finally {
            // Clear the attempt
            this._pending = null;
        }
    }

    get accountServiceURL() {
        return `${this._getEndpoint('issuer')}/account`;
    }

    get userLogoutURL() {
        return this._generateURL('end_session_endpoint');
    }

    /** initiates the logout procedure */
    logout() {
        window.location.assign(this.userLogoutURL);
    }

    /**
	 * returns a token, either from the storage layer or
	 * after completing the authentication procedure.
	 */
    initialize() {
        log('Initialize');
        if (!window.frameElement) {
            // Start the signin if we are in the top frame
            log('Signin');
            this._signin();
        } else {
            this._driver.resolve();
        }
    }
}
