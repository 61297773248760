import invariant from "invariant";
//http://localhost:7092/ticket/oauth#state=%2Fticket
//&session_state=52a869bd-56e7-41ee-98f6-e53f2948a81d
//&iss=https%3A%2F%2Faccounts.secure-regs.com%2Frealms%2Faccounts
//&access_token=eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJOdUV1UlMxZWd6V1RFS1Z6SXg4UEtFSGNiWFh4RG5uV1JxWkJ2ZFlPOEdRIn0.eyJleHAiOjE3MDMxNzY3NjIsImlhdCI6MTcwMzE3NTg2MiwiYXV0aF90aW1lIjoxNzAzMTc0NDc0LCJqdGkiOiI1ZGI2NTViNC03YjBlLTQ5ZDgtODkxNS04MTdhOGRjMTljZTYiLCJpc3MiOiJodHRwczovL2FjY291bnRzLnNlY3VyZS1yZWdzLmNvbS9yZWFsbXMvYWNjb3VudHMiLCJhdWQiOiJ3aWxseWRldiIsInN1YiI6ImI2MmZhYmRiLTUwNjEtNDMzYS1hYjNjLWRiNmFhYzY0ZTg5MCIsInR5cCI6IkJlYXJlciIsImF6cCI6IndpbGx5ZGV2Iiwic2Vzc2lvbl9zdGF0ZSI6IjUyYTg2OWJkLTU2ZTctNDFlZS05OGY2LWU1M2YyOTQ4YTgxZCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cDovL2xvY2FsaG9zdDo3MDkyIl0sInNjb3BlIjoiZW1haWwgQWNjb3VudHMgcHJvZmlsZSBBdWRpZW5jZS13aWxseWRldiIsInNpZCI6IjUyYTg2OWJkLTU2ZTctNDFlZS05OGY2LWU1M2YyOTQ4YTgxZCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZGF0YSI6eyJmaXJzdG5hbWUiOiJHcsOpZ29pcmUiLCJsb2NhbGUiOiJlbiIsImVtYWlsIjoiZ3JlZ29pcmUucm9jaGVyQHdlZXpldmVudC5jb20ifSwibmFtZSI6Ikdyw6lnb2lyZSBSb2NoZXIiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJncmVnb2lyZS5yb2NoZXJAd2VlemV2ZW50LmNvbSIsImxvY2FsZSI6ImVuIiwiZ2l2ZW5fbmFtZSI6Ikdyw6lnb2lyZSIsImZhbWlseV9uYW1lIjoiUm9jaGVyIiwiZW1haWwiOiJncmVnb2lyZS5yb2NoZXJAd2VlemV2ZW50LmNvbSJ9.MOI1eLpFn3_ETObUqdkTKYTvTaNzPrh307xOnmdXbSTqM0awgoUcatMCE4vIFyuWIdsSqcui_qOD1wkcDZNZPpuqHRmkfVNg9HwOeAFCgRp_4m1vsRm2rKg0Ii67Rg7mJdQGSJahfABTm6s1FEMsYmHIjy8QwP0BO1C1aL4oyDqBlmtf4GyZSaM5KZ_nhfXrQV37KqVjZIZ6ZhjtnNLTkdxOkVfFKgeG8R22IzDcoQQDevavUeYSWy_e5t0bBHjDmavIXjCmRrFu0fcANgviPrCv1HmEgr1NFOCgyrqHK3V4VEWl7LEEsEiRPQ1CGFEpBtGlJ9XW94ZfnRyCwGUe-w
//&token_type=Bearer&expires_in=900

if (!global.TextDecoder) {
    global.TextDecoder = require('util').TextDecoder;
}

// Parse the JWT payload
const parseToken = (token) => {
    const rawBinary = atob(token.split(".")[1]);

    const dc = new TextDecoder(); // default TextDecoder is UTF-8
    const rawText = dc.decode(Uint8Array.from(rawBinary, m => m.codePointAt(0)));
    return JSON.parse(rawText);
};

class AccountInfo {
    static legacy(values) {
        /*
        {
            "user_id": " ... ",
            "name": " ... ",
            "firstname": " ... ",
            "email": ".....@weezevent.com",
            "ac": 1,
            "locale": "fr_FR",
            "hasMfa": true,
            "created": "2018-05-07 12:49:53"
        },
        */

        return new AccountInfo({
            locale: values.locale,
            sub: values.user_id,
            name: `${values.firstname || ''}${(values.firstname && values.name) ? ' ' : ''}${values.name || ''}`,
            email: values.email,
            firstName: values.firstname,
            lastName: values.name,
        });
        /*
  "exp": 1703080238,
  "iat": 1703079338,
  "auth_time": 1703078189,
  "jti": "a9627d03-3993-426a-b998-a9d9ce823566",
  "iss": "https://accounts.secure-regs.com/realms/accounts",
  "aud": "account",
  "sub": " ... ",
  "typ": "Bearer",
  "azp": "willydev",
  "session_state": "0751d031-4b22-403c-87c7-83a8e4ddac2b",
  "acr": "0",
  "allowed-origins": [
    "http://localhost:7092"
  ],
  "realm_access": {
    "roles": [
      "default-roles-accounts",
      "offline_access",
      "uma_authorization"
    ]
  },
  "resource_access": {
    "account": {
      "roles": [
        "manage-account",
        "manage-account-links",
        "view-profile"
      ]
    }
  },
  "scope": "email profile",
  "sid": "0751d031-4b22-403c-87c7-83a8e4ddac2b",
  "email_verified": false,
  "name": "...",
  "preferred_username": "...@weezevent.com",
  "locale": "en",
  "given_name": " ... ",
  "family_name": " ... ",
  "email": "....@weezevent.com"
}
         */
        return this.payload;
    }

    constructor({ locale, sub, name, email, firstName, lastName }) {
        this.locale = locale;
        this.userID = sub;
        this.name = name;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    toString() {
        return this.name;
    }
}

export class Token {
    constructor(raw) {
        const { access_token: accessToken, token_type: tokenType } = raw;
        invariant(typeof accessToken === 'string' && accessToken.startsWith('ey'), `Raw ${raw} is not a JWT`);
        this.tokenType = tokenType || 'Bearer';
        this.token = accessToken;

        const payload = this.payload = parseToken(accessToken);
        this.accountInfo = payload.data ? AccountInfo.legacy(payload.data) : new AccountInfo(payload);
    }

    get authorizationHeader() {
        return `${this.tokenType} ${this.token}`;
    }

    /** The expiration timestamp of the token*/
    get expire() {
        return this.payload.exp;
    }

    /** is true if the token is expired */
    get isExpired() {
        return Date.now() / 1000 > this.expire;
    }

    getAccountInfo() {
        return this.accountInfo;
    }
}
