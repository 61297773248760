class Cache {
	constructor() {
		this.cache = {};
	}

	get(key) {
		if(!(key in this.cache)) {
			return undefined;
		}

		const { value, timeout } = this.cache[key];
		if(timeout < Date.now()) {
			// Expired, remove from cache
			delete this.cache[key];

			// Return undefined (!= null) to indicate that there is nothing there
			return undefined;
		}

		return value;
	}

	set({ key, value, timeout=30 }) {
		const cache_value = {
			value,
			timeout: Date.now() + (timeout * 1000)
		};

		this.cache[key] = cache_value;
	}

	has(key) {
		return (key in this.cache);
	}

	clear() {
		this.cache = {};
	}

	remove(key) {
		delete this.cache[key];
	}

	keys() {
		return Object.keys(this.cache);
	}
}

const cache = new Cache();

export class FetchCache extends Cache {
	request_key({ url, auth='' }) {
		return `${url}::${auth}`;
	}

	get_key({ url, headers }) {
		if(!url) {
			throw new Error('Url needed to set in cache');
		}

		return this.request_key({ url, auth: headers.get('Authorization') });
	}

	get_request({ url, headers={} }) {
		const key = this.get_key({ url, headers });
		return this.get(key);
	}

	set_request({ url, headers={}, timeout, value }) {
		const key = this.get_key({ url, headers });
		return this.set({
			key,
			value,
			timeout
		});
	}

	remove_request({ url }) {
		const keys = this.keys().filter(key => {
			// "Pure" url /ticket/rates OR filtered /ticket/rates?search=plep
			return key.startsWith(`${url}::`) || key.startsWith(`${url}?`);
		});
		for(const key of keys) {
			this.remove(key);
		}
	}
}

export default cache;
