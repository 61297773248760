import React from 'react';

export const useForm = (initialForm = {}, validators = {}) => {
    const [form, setForm] = React.useState(initialForm);
    const [errors, setErrors] = React.useState({});

    const isValid = (validators, name, value, form) => {
        let result = null;
        let validator = validators[name];

        if (validator['conditionals']) {
            result = true;

            // validate all validators conditions
            for (let field in validators[name]['conditionals']) {
                if (validators[name]['conditionals'][field][0].isValid(form[name], form[field])) {
                    let valid = validators[name]['conditionals'][field][1](name, form[name], form);
                    if (valid !== true) {
                        result = validators[name]['conditionals'][field][0].getErrorLabel();
                    }
                }
            }
        }

        if (!validator['conditionals'] && validator instanceof Function) {
            result = validator(name, value, form);
        }

        if(Array.isArray(validator)) {
            result = validator.every(v => v(name, value, form));
        }

        return result;
    };

    const setValue = React.useCallback(
        (name, value) => {
            setForm(form => {
                if (!(name in form)) {
                    throw new Error(`[Form] ${name} is not a valid form key`);
                }

                if (validators[name]) {
                    let result = isValid(validators, name, value, form);

                    // Common
                    if (result !== true) {
                        setErrors(errors => ({ ...errors, [name]: result }));
                    } else {
                        setErrors(errors => {
                            let tmpErrors = { ...errors };
                            delete tmpErrors[name];
                            return tmpErrors;
                        });
                    }
                }

                let newForm = { ...form };
                // Allows for easier API
                if(name instanceof Object) {
                    newForm = {...newForm, ...name};
                } else {
                    newForm[name] = value;
                }

                return newForm;
            });
        },
        [validators]
    );

    const setObject = React.useCallback((obj = {}, merge = false) => {
        setForm(form => {
            let newForm = merge ? { ...form, ...obj } : obj;
            return newForm;
        });
    }, []);

    const validate = React.useCallback(() => {
        let tmpErrors = {};
        for (let name in form) {
            // eslint-disable-line no-unused-vars
            if (validators[name]) {
                let result = isValid(validators, name, form[name], form);
                if (result !== true) tmpErrors[name] = result;
            }
        }
        setErrors(tmpErrors);
        return !Object.keys(tmpErrors).length;
    }, [form]);

    return [form, setValue, setObject, errors, validate, setErrors];
};

/**
 * Validator for field required !
 * if field is not filled , error = { field : "ticket.common.required"} to display "this field is required !".
 * else, display true and remove field in error object
 * @param {*} name
 * @param {*} field
 * @param {*} dateForm
 */
export function requiredField(name, field) {
    let valueToCheck = field;
    if (!Number.isNaN(valueToCheck) && valueToCheck !== null) {
        valueToCheck = valueToCheck.toString();
    }
    if (!valueToCheck || !valueToCheck.trim()) {
        return 'ticket.common.required';
    }

    return true;
}

/**
 * Validator for field required email !
 * @param {*} name
 * @param {*} field
 * @param {*} dateForm
 */
export function requiredEmailField(name, field) {
    let valueToCheck = field;
    if (Number.isInteger(valueToCheck)) {
        valueToCheck = valueToCheck.toString();
    }
    if (!valueToCheck || !valueToCheck.trim()) {
        return 'ticket.common.required';
    }

    let re = /\S+@\S+\.\S+/;
    if (!re.test(valueToCheck)) {
        return 'ticket.common.validator_email';
    }
    return true;
}

/**
 * Validator for field required email !
 * @param {*} name
 * @param {*} field
 * @param {*} dateForm
 */
export function emailField(name, field) {
    let valueToCheck = field;
    if (Number.isInteger(valueToCheck)) {
        valueToCheck = valueToCheck.toString();
    }

    if (!valueToCheck || valueToCheck.length === 0) {
        return true;
    }

    let re = /\S+@\S+\.\S+/;
    if (!re.test(valueToCheck)) {
        return 'ticket.common.validator_email';
    }
    return true;
}

export class Conditional {
    constructor(condition, error = 'error', value = null, expected_result = null) {
        this.value = value;
        this.expected_result = expected_result;
        this.condition = condition;
        this.errorKey = error;
    }

    /**
     * Null condition : If value of field condition is null return true
     * @param {*} fieldCondition
     */
    nullCondition(fieldCondition) {
        if (fieldCondition) {
            return false;
        }
        return true;
    }

    /**
     * Not Null condition : If value of filed condition is not null return true
     * @param {*} fieldCondition
     */
    notNullCondition(fieldCondition) {
        if (!fieldCondition) {
            return false;
        }
        return true;
    }

    equalsCondition(fieldCondition) {
        if (fieldCondition === this.value) {
            return true;
        }
        return false;
    }

    differentCondition(fieldCondition) {
        if (fieldCondition !== this.value) {
            return true;
        }
        return false;
    }

    getErrorLabel() {
        return this.errorKey;
    }

    isValid(field, fieldCondition) {
        switch (this.condition) {
            case 'null':
                return this.nullCondition(fieldCondition);
            case 'not_null':
                return this.notNullCondition(fieldCondition);
            case 'equals':
                return this.equalsCondition(fieldCondition);
            case 'different':
                return this.differentCondition(fieldCondition);
            default:
                return true;
        }
    }
}
