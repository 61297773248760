export class MustBeOverridenError extends Error {}

export class ArgumentError extends Error {}

/**
 * Extends error to be able to keep external data
 */
export class RequestError extends Error {
    constructor(message, url, code, response, headers) {
        super(message);
        this.message = message;
        this.url = url;
        this.code = code;
        this.response = response;
        this.name = this.constructor.name;
        this.headers = headers;
    }

    toString() {
        return `[Fetch] Could not fetch ${this.url} (${this.code}):\n${this.response}\n${this.stack}`;
    }

    parseError(){
        let contentType = this.headers.get("Content-Type");
        if (contentType.includes("application/json")){
            return JSON.parse(this.response);
        }
        return false;
    }

    parseErrorRecursiveTool(data, error, translate, prefixKey){
        if (data instanceof Array){
            for ( let line of data){
                error = this.parseErrorRecursiveTool(line, error, translate, prefixKey);
            }
        } else if(data instanceof Object){
            for (const [key, value] of Object.entries(data)){
                if(value instanceof Object){
                    if(translate){
                        let i18nTrans = translate(`${prefixKey}${key}`);
                        error+= `${i18nTrans} - `;
                    }else{
                        error+= `${key} - `;
                    }
                }
                error = this.parseErrorRecursiveTool(value, error, translate, prefixKey);
            }
        }else{
            error+= `${data}\n`;
        }

        return error;
    }

    parseErrorRecursive(translate=null, prefixKey=''){
        let contentType = this.headers.get("Content-Type");
        if (contentType.includes("application/json")){
            let error = this.parseErrorRecursiveTool(JSON.parse(this.response), "", translate, prefixKey);
            return error;
        }
        return false;
    }
}
