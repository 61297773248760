import React from "react";
import { TokenContext } from "./contexts";

/* Authentication connector, it injects the token into the Authorization header */
export function useAuthenticationMiddleware() {
    const ref = React.useRef();
    const context = React.useContext(TokenContext);
    ref.current = context.authorizationHeader;

    return function withAuthentication(fetch) {
        return async (url, options={}) => {
            options.headers = options.headers || {};
            if (!('Authorization' in options.headers)) {
                options.headers.Authorization = ref.current;
            } else if(!options.headers.Authorization) {
                // Strip empty Authorization headers
                delete options.headers.Authorization;
            }
            return fetch(url, options);
        };
    };
};

export function useOnTokenCallback(fn) {
    const context = React.useContext(TokenContext);
    React.useEffect(() => {
        fn(context.authorizationHeader);
    }, [context.authorizationHeader, fn]);
    return null;
}
