import React from "react";
import invariant from "invariant";
import PropTypes from "prop-types";
import Engine from "./engine";
import {EngineContext, TokenContext} from "./contexts";


function makeEndpoints({ oAuthUrl, OIDCUrl, endpoints }) {
    if (endpoints) {
        // Explicitely tel us what are the endpoints
        return endpoints;
    }
    if (oAuthUrl) {
        // guess the endpoints based on the accounts convention
        return {
            authorization_endpoint: `${oAuthUrl}/signin`,
            userinfo_endpoint: `${oAuthUrl}/settings/account`,
            end_session_endpoint: `${oAuthUrl}/logout`,
        };
    }
    invariant(OIDCUrl, 'one of endpoints, OIDCUrl or oAuthUrl is required');
    const metadata = `${OIDCUrl}/.well-known/openid-configuration`;
    return fetch(metadata).then(resp => resp.json());
}

export const Connect = ({ driver, config, children, fallback, redirectPath }) => {
    invariant(redirectPath.startsWith('/'), 'invalid path');

    // Pointer to the engine
    const ref = React.useRef();
    // State of the current token
    const [currentToken, setCurrentToken] = React.useState();

    React.useEffect(() => {
        const endpointsPromise = new Promise(resolve => resolve(makeEndpoints(config)));
        endpointsPromise.then(endpoints => {
            ref.current = new Engine({
                // Extract the scheme + host from Accounts URL
                endpoints: endpoints,
                clientId: config.clientId,
                onResolve: setCurrentToken,
                driver,
                redirectURI: `${window.location.protocol}//${window.location.host}${redirectPath}`,
            });

            ref.current.initialize();
        });
    }, [config, redirectPath, driver]);

    if (!ref.current) {
        return fallback || null;
    }

    // Inject the current value of the token and render the rest of the application
    return <EngineContext.Provider value={ ref.current }>
        {
            // Check if we have a token
            currentToken
                ? <TokenContext.Provider value={ currentToken } >
                    {children}
                </TokenContext.Provider>
                : fallback
        }
    </EngineContext.Provider>;
};

Connect.propTypes = {
    fallback: PropTypes.node,
    driver: PropTypes.object,
    children: PropTypes.node.isRequired,
    redirectPath: PropTypes.string.isRequired,
    config: PropTypes.shape({
        oAuthUrl: PropTypes.string,
        OIDCUrl: PropTypes.string,
        endpoints: PropTypes.object,
        clientId: PropTypes.string.isRequired,
    }).isRequired,
};
