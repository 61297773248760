{
  "name": "oidc-client-ts",
  "version": "3.0.0",
  "description": "OpenID Connect (OIDC) & OAuth2 client library",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/authts/oidc-client-ts.git"
  },
  "homepage": "https://github.com/authts/oidc-client-ts#readme",
  "license": "Apache-2.0",
  "main": "dist/umd/oidc-client-ts.js",
  "types": "dist/types/oidc-client-ts.d.ts",
  "exports": {
    ".": {
      "types": "./dist/types/oidc-client-ts.d.ts",
      "import": "./dist/esm/oidc-client-ts.js",
      "require": "./dist/umd/oidc-client-ts.js"
    },
    "./package.json": "./package.json"
  },
  "files": [
    "dist"
  ],
  "keywords": [
    "authentication",
    "oauth2",
    "oidc",
    "openid",
    "OpenID Connect"
  ],
  "scripts": {
    "build": "node scripts/build.js && npm run build-types",
    "build-types": "tsc -p tsconfig.build.json && api-extractor run",
    "clean": "git clean -fdX dist lib *.tsbuildinfo",
    "prepack": "npm run build",
    "test": "tsc && jest",
    "typedoc": "typedoc",
    "lint": "eslint --max-warnings=0 --cache .",
    "prepare": "husky install"
  },
  "dependencies": {
    "jwt-decode": "^4.0.0"
  },
  "devDependencies": {
    "@microsoft/api-extractor": "^7.35.0",
    "@testing-library/jest-dom": "^6.0.0",
    "@types/jest": "^29.2.3",
    "@types/node": "^20.8.2",
    "@typescript-eslint/eslint-plugin": "^6.4.1",
    "@typescript-eslint/parser": "^6.4.1",
    "esbuild": "^0.20.0",
    "eslint": "^8.5.0",
    "eslint-plugin-testing-library": "^6.0.0",
    "http-proxy-middleware": "^2.0.1",
    "husky": "^9.0.6",
    "jest": "^29.3.1",
    "jest-environment-jsdom": "^29.3.1",
    "jest-mock": "^29.3.1",
    "lint-staged": "^15.0.1",
    "ts-jest": "^29.0.3",
    "typedoc": "^0.25.0",
    "typescript": "~5.3.3",
    "yn": "^5.0.0"
  },
  "engines": {
    "node": ">=18"
  },
  "lint-staged": {
    "*.{js,jsx,ts,tsx}": "eslint --cache --fix"
  }
}
